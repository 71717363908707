import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { Link, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import LightButton from '../components/buttons/light_button'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'

import authors from '../../static/_authors/authors.json'

function Template ({ data }) {
  const { markdownRemark: post } = data

  const author = authors.authors.find(
    item => item.firstName === post.frontmatter.author
  )
  // const post = data.markdownRemark;
  deckDeckGoHighlightElement()
  return (
    <Layout>
      <SEO
        metaImage={post.frontmatter.featuredimage.childImageSharp.fluid}
        metaTags={post.frontmatter.tags.join(', ').toString()}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        type="article"
      />
      <PageHeader
        className="pink"
        title="IT BLOG"
        background={data.bgImage.childImageSharp.fluid}
        subtitle={post.frontmatter.description}
        previousPage="BLOG"
        previousPageLink="/blog/"
        thisPage={post.frontmatter.title}
      />
      <div className="container cases-project-image pb-5">
        {post.frontmatter.featuredimage && (
          <img
            style={{
              marginTop: -270,
              width: '100%',
              position: 'relative',
              border: '15px solid #fff'
            }}
            src={
              post.frontmatter.featuredimage &&
              post.frontmatter.featuredimage.childImageSharp.fluid.src
            }
            alt={post.frontmatter.title}
          />
        )}
      </div>
      <div className="col-md-6 pt-4 mx-auto">
        <div className="blog-post-header">
          <div className="blog-post-category">
            <a href="/#">{post.frontmatter.category}</a>
          </div>
          <h2 className="blog-post-title">{post.frontmatter.title}</h2>

          <div className="blog-post-meta">
            <div className="blog-post-author-content">
              <div className="d-flex align-items-center">
                <div style={{ width: 40 }}>
                  <div className="post-avatar">
                    <img src={author.avatar} alt="" />
                  </div>
                </div>

                <span className="post-name">
                  <a href="/#">
                    {author.firstName} {author.lastName}
                  </a>
                </span>
              </div>
              <span className="post-date">{post.frontmatter.date}</span>
            </div>
          </div>
        </div>

        <div className="blog-post-content">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>

        <div className="blog-post-footer my-5 pb-5">
          <div className="post-tags">
            <span>Tags:</span>
            {post.frontmatter.tags &&
              post.frontmatter.tags.map((item, index) => {
                return (
                  <a key={index} href={`/blog/tag_${item}`}>
                    {item}
                  </a>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Template

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredimage {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
        description
        tags
        author
      }
    }
    bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const PageHeader = ({
  background,
  title,
  subtitle,
  buttonTitle,
  buttonLink,
  thisPage,
  className,
  children,
  previousPage,
  previousPageLink
}) => {
  return (
    <BackgroundImage fluid={background} className={`page-header ${className}`}>
      <div className={`overlay-page-header ${className}`}></div>
      <div className="page-header-inner wrap">
        <div className="col-md-5 mx-auto">
          <div className="page-title">
            <h2>{title}</h2>
          </div>
          <div className="page-subtitle">
            <p>{subtitle}</p>
          </div>
          {buttonTitle
            ? (
            <div>
              <div style={{ height: 30 }}></div>
              <LightButton title={buttonTitle} />
            </div>
              )
            : null}

          <div style={{ height: 30 }}></div>
          <div className="breadcrumb-explorelogy">
            <div className="breadcrumb-inner">
              <span>
                <Link to="/">Explorelogy</Link>
              </span>

              <span className="link-item">
                <Link to={previousPageLink}>{previousPage}</Link>
              </span>

              <span>{thisPage}</span>
            </div>
          </div>
          <div style={{ height: 300 }}></div>
          {children}
        </div>
      </div>
    </BackgroundImage>
  )
}
